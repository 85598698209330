// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/PBSSans.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'PBSSans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.header {
    background-color: var(--primary);
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.logo img {
    height: 30px;
    margin-left: 25px;
}

@media (max-width: 480px) {
    .header {
        height: 60px;
    }

    .logo img {
        height: 30px;
        margin-left: 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,4CAAuC;AAC3C;;AAEA;IACI,gCAAgC;IAChC,YAAY;IACZ,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,YAAY;QACZ,iBAAiB;IACrB;AACJ","sourcesContent":["@font-face {\n    font-family: 'PBSSans';\n    src: url('../assets/fonts/PBSSans.ttf');\n}\n\n.header {\n    background-color: var(--primary);\n    height: 80px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.logo img {\n    height: 30px;\n    margin-left: 25px;\n}\n\n@media (max-width: 480px) {\n    .header {\n        height: 60px;\n    }\n\n    .logo img {\n        height: 30px;\n        margin-left: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
