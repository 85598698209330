// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/icon?family=Material+Icons);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 500px;
    height: 55px;
    box-shadow: 0 0 10px var(--shadow);
    display: flex;
    overflow-x: auto;
    background-color: white;
}

.nav-link {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-family: 'PBSSans', sans-serif;
    font-size: 14px;
    color: var(--secondary);
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

.nav-link-active {
    color: var(--primary);
}

.nav-icon {
    font-size: 24px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Nav.css"],"names":[],"mappings":"AAEA;IACI,eAAe;IACf,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,kCAAkC;IAClC,aAAa;IACb,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,kCAAkC;IAClC,eAAe;IACf,uBAAuB;IACvB,qBAAqB;IACrB,wCAAwC;AAC5C;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["@import url('https://fonts.googleapis.com/icon?family=Material+Icons');\n\n.nav {\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n    max-width: 500px;\n    height: 55px;\n    box-shadow: 0 0 10px var(--shadow);\n    display: flex;\n    overflow-x: auto;\n    background-color: white;\n}\n\n.nav-link {\n    display: flex;\n    flex-grow: 1;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-width: 50px;\n    overflow: hidden;\n    white-space: nowrap;\n    font-family: 'PBSSans', sans-serif;\n    font-size: 14px;\n    color: var(--secondary);\n    text-decoration: none;\n    -webkit-tap-highlight-color: transparent;\n}\n\n.nav-link-active {\n    color: var(--primary);\n}\n\n.nav-icon {\n    font-size: 24px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
