// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location {
    text-align: left;
    padding: 30px;
    color: #204553;
}

.location h1 {
    font-size: 50px;
    font-weight: normal;
    line-height: 20px;
    margin-bottom: 20px;
}

.find {
    display: flex;
    text-align: left;
    cursor: pointer;
    margin-bottom: 40px;
}

.find span {
    margin-left: 10px;
    font-size: 18px;
}

.find img {
    width: 24px;
    height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Location.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".location {\n    text-align: left;\n    padding: 30px;\n    color: #204553;\n}\n\n.location h1 {\n    font-size: 50px;\n    font-weight: normal;\n    line-height: 20px;\n    margin-bottom: 20px;\n}\n\n.find {\n    display: flex;\n    text-align: left;\n    cursor: pointer;\n    margin-bottom: 40px;\n}\n\n.find span {\n    margin-left: 10px;\n    font-size: 18px;\n}\n\n.find img {\n    width: 24px;\n    height: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
