// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.near-places {
    padding-left: 30px;
    margin-bottom: 20px;
}

.near-places h2 {
    margin-bottom: 20px;
    font-weight: normal;
    color: #204553;
}

.cards-container {
    display: flex;
    overflow-x: auto;
}

.cards-container::-webkit-scrollbar {
    display: none;
}

.cards-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}`, "",{"version":3,"sources":["webpack://./src/components/NearPlaces.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,wBAAwB;IACxB,qBAAqB;AACzB","sourcesContent":[".near-places {\n    padding-left: 30px;\n    margin-bottom: 20px;\n}\n\n.near-places h2 {\n    margin-bottom: 20px;\n    font-weight: normal;\n    color: #204553;\n}\n\n.cards-container {\n    display: flex;\n    overflow-x: auto;\n}\n\n.cards-container::-webkit-scrollbar {\n    display: none;\n}\n\n.cards-container {\n    -ms-overflow-style: none;\n    scrollbar-width: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
