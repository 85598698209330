// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floating-btn {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    height: 80px;
    width: 80px;
    background-color: var(--primary);
    color: white;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 40px !important;
    text-decoration: none;
    box-shadow: 2px 2px 5px var(--shadow);
}`, "",{"version":3,"sources":["webpack://./src/components/FloatingButton.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,SAAS;IACT,2BAA2B;IAC3B,YAAY;IACZ,WAAW;IACX,gCAAgC;IAChC,YAAY;IACZ,wBAAwB;IACxB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,0BAA0B;IAC1B,qBAAqB;IACrB,qCAAqC;AACzC","sourcesContent":[".floating-btn {\n    position: fixed;\n    bottom: 10px;\n    left: 50%;\n    transform: translateX(-50%);\n    height: 80px;\n    width: 80px;\n    background-color: var(--primary);\n    color: white;\n    display: flex !important;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n    font-size: 40px !important;\n    text-decoration: none;\n    box-shadow: 2px 2px 5px var(--shadow);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
