// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    flex: 0 0 auto;
    width: 150px;
    height: 230px;
    margin-right: 10px;
    border-radius: 20px;
    overflow: hidden;
    background-color: #d9d9d9;
    text-align: center;
}

.card img {
    width: 140px;
    height: 140px;
    padding: 5px 0;
    border-radius: 20px;
    object-fit: cover;
}

.card-name {
    padding: 5px 5px;
    margin-left: 15px;
    vertical-align: bottom;
    text-align: left;
    font-size: 20px;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Card.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,YAAY;AAChB","sourcesContent":[".card {\n    flex: 0 0 auto;\n    width: 150px;\n    height: 230px;\n    margin-right: 10px;\n    border-radius: 20px;\n    overflow: hidden;\n    background-color: #d9d9d9;\n    text-align: center;\n}\n\n.card img {\n    width: 140px;\n    height: 140px;\n    padding: 5px 0;\n    border-radius: 20px;\n    object-fit: cover;\n}\n\n.card-name {\n    padding: 5px 5px;\n    margin-left: 15px;\n    vertical-align: bottom;\n    text-align: left;\n    font-size: 20px;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
